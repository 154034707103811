import { v4 as uuidv4 } from "uuid";
import { generateStaticQuestionId } from "../../../../../utils/goalsUtils";

export const cultureOfInclusion = {
    "blockId": uuidv4(),
    "description": "<p>Enable everyone to do their best work.</p>",
    "goalId": "dynamic360Strength",
    isRequired: true,
    feedbackBasedCondition: [
        {
            answeringCondition: [
                {
                    columnValues: ["1", "2"],
                    numberOfAnswersNeeded: 3,
                },
                {
                    columnValues: ["3", "4"],
                    numberOfAnswersNeeded: 3,
                },
                {
                    columnValues: ["5", "6"],
                    numberOfAnswersNeeded: 3,
                }
            ]
        }

    ],
    allowNAOption: true,
    "questions": [
        {
            "question": "",
            "options": [
                {
                    "label": "Never",
                    "value": "1"
                },
                {
                    "label": "Rarely",
                    "value": "2"
                },
                {
                    "label": "Occasionally",
                    "value": "3"
                },
                {
                    "label": "Sometimes",
                    "value": "4"
                },
                {
                    "label": "Usually",
                    "value": "5"
                },
                {
                    "label": "Always",
                    "value": "6"
                },
                { "label": "Can't answer", "value": "0" }
            ],
            "questionDimension": "Custom Matrix",
            "type": "matrix",
            "qid": uuidv4(),
            "matrixQuestions": [
                {
                    "subCategoryId": generateStaticQuestionId("Builds Trust"),
                    "subCategory": "Builds Trust",
                    "staticQuestionId": generateStaticQuestionId("Creates an environment where all team members feel valued, respected, and have a sense of belonging"),
                    "HTMLText": "<p>Creates an environment where all team members feel valued, respected, and have a sense of belonging</p>",
                    "isSelected": true,
                    "text": "Creates an environment where all team members feel valued, respected, and have a sense of belonging",
                    "value": uuidv4()
                },
                {
                    "subCategoryId": generateStaticQuestionId("Builds Trust"),
                    "subCategory": "Builds Trust",
                    "staticQuestionId": generateStaticQuestionId("Empowers and engages individuals with varied backgrounds by ensuring all team members feel heard"),
                    "HTMLText": "<p>Empowers and engages individuals with varied backgrounds by ensuring all team members feel heard</p>",
                    "isSelected": true,
                    "text": "Empowers and engages individuals with varied backgrounds by ensuring all team members feel heard",
                    "value": uuidv4()
                },
                {
                    "subCategoryId": generateStaticQuestionId("Builds Trust"),
                    "subCategory": "Builds Trust",
                    "staticQuestionId": generateStaticQuestionId("Integrates data, facts, and different points of views when making decisions."),
                    "HTMLText": "<p>Integrates data, facts, and different points of views when making decisions.</p>",
                    "isSelected": true,
                    "text": "Integrates data, facts, and different points of views when making decisions.",
                    "value": uuidv4()
                },
                {
                    "subCategoryId": generateStaticQuestionId("Leverage different perspectives"),
                    "subCategory": "Leverage different perspectives",
                    "staticQuestionId": generateStaticQuestionId("Leverages a wide range of skills, backgrounds, viewpoints, and experiences to achieve goals"),
                    "HTMLText": "<p>Leverages a wide range of skills, backgrounds, viewpoints, and experiences to achieve goals</p>",
                    "isSelected": true,
                    "text": "Leverages a wide range of skills, backgrounds, viewpoints, and experiences to achieve goals",
                    "value": uuidv4()
                },
                {
                    "subCategoryId": generateStaticQuestionId("Leverage different perspectives"),
                    "subCategory": "Leverage different perspectives",
                    "staticQuestionId": generateStaticQuestionId("Actively seeks different viewpoints when making decisions"),
                    "HTMLText": "<p>Actively seeks different viewpoints when making decisions</p>",
                    "isSelected": true,
                    "text": "Actively seeks different viewpoints when making decisions",
                    "value": uuidv4()
                },
                {
                    "subCategoryId": generateStaticQuestionId("Adapt to Cultural Contexts"),
                    "subCategory": "Adapt to Cultural Contexts",
                    "staticQuestionId": generateStaticQuestionId("Adapts behaviors based on cultural contexts, demonstrating cultural awareness and sensitivity"),
                    "HTMLText": "<p>Adapts behaviors based on cultural contexts, demonstrating cultural awareness and sensitivity</p>",
                    "isSelected": true,
                    "text": "Adapts behaviors based on cultural contexts, demonstrating cultural awareness and sensitivity",
                    "value": uuidv4()
                },
                {
                    "subCategoryId": generateStaticQuestionId("Adapt to Cultural Contexts"),
                    "subCategory": "Adapt to Cultural Contexts",
                    "staticQuestionId": generateStaticQuestionId("Seeks opportunities to learn about different cultures, value systems, and beliefs"),
                    "HTMLText": "<p>Seeks opportunities to learn about different cultures, value systems, and beliefs</p>",
                    "isSelected": true,
                    "text": "Seeks opportunities to learn about different cultures, value systems, and beliefs",
                    "value": uuidv4()
                },
                {
                    "subCategoryId": generateStaticQuestionId("Adapt to Cultural Contexts"),
                    "subCategory": "Adapt to Cultural Contexts",
                    "staticQuestionId": generateStaticQuestionId("Challenges own and others' biases and beliefs when making decisions"),
                    "HTMLText": "<p>Challenges own and others' biases and beliefs when making decisions</p>",
                    "isSelected": true,
                    "text": "Challenges own and others' biases and beliefs when making decisions",
                    "value": uuidv4()
                },
            ],
            "questionLabel": "Custom Matrix"
        }
    ],
    "isSelected": true,
    "type": "matrix",
    "dimension": "Inclusion"
}