import { v4 as uuidv4 } from "uuid";
import { generateStaticQuestionId } from "../../../../../utils/goalsUtils";

export const selfAwareness = {
    "blockId": uuidv4(),
    "description": "<p>Grow Oneself: Understand the shift in perceptions and accountabilities that are needed for effective management of high performing and engaged individuals and teams. Lead with the heart; understand how EQ and empathy can increase effectiveness and impact.</p>",
    "goalId": "dynamic360Strength",
    isRequired: true,
    feedbackBasedCondition: [
        {
            answeringCondition: [
                {
                    columnValues: ["1", "2"],
                    numberOfAnswersNeeded: 3,
                },
                {
                    columnValues: ["3", "4"],
                    numberOfAnswersNeeded: 3,
                },
                {
                    columnValues: ["5", "6"],
                    numberOfAnswersNeeded: 3,
                }
            ]
        }

    ],
    allowNAOption: true,
    "questions": [
        {
            "question": "",
            "options": [
                {
                    "label": "Never",
                    "value": "1"
                },
                {
                    "label": "Rarely",
                    "value": "2"
                },
                {
                    "label": "Occasionally",
                    "value": "3"
                },
                {
                    "label": "Sometimes",
                    "value": "4"
                },
                {
                    "label": "Usually",
                    "value": "5"
                },
                {
                    "label": "Always",
                    "value": "6"
                },
                { "label": "Can't answer", "value": "0" }
            ],
            "questionDimension": "Custom Matrix",
            "type": "matrix",
            "qid": uuidv4(),
            "matrixQuestions": [
                {
                    "subCategoryId": generateStaticQuestionId("Seek and Act on Feedback"),
                    "subCategory":"Seek and Act on Feedback",
                    "staticQuestionId": generateStaticQuestionId("Demonstrates awareness of own strengths and development areas"),
                    "HTMLText": "<p>Demonstrates awareness of own strengths and development areas</p>",
                    "isSelected": true,
                    "text": "Demonstrates awareness of own strengths and development areas",
                    "value": uuidv4()
                },
                {
                    "subCategoryId": generateStaticQuestionId("Seek and Act on Feedback"),
                    "subCategory": "Seek and Act on Feedback",
                    "staticQuestionId": generateStaticQuestionId("Understands the impact that own management style, values, and authority have on others"),
                    "HTMLText": "<p>Understands the impact that own management style, values, and authority have on others</p>",
                    "isSelected": true,
                    "text": "Understands the impact that own management style, values, and authority have on others",
                    "value": uuidv4()
                },
                {
                    "subCategoryId": generateStaticQuestionId("Seek and Act on Feedback"),
                    "subCategory": "Seek and Act on Feedback",
                    "staticQuestionId": generateStaticQuestionId("Actively seeks candid feedback on own performance and personal impact"),
                    "HTMLText": "<p>Actively seeks candid feedback on own performance and personal impact</p>",
                    "isSelected": true,
                    "text": "Actively seeks candid feedback on own performance and personal impact",
                    "value": uuidv4()
                },
                {
                    "subCategoryId": generateStaticQuestionId("Takes Accountability"),
                    "subCategory": "Takes Accountability",
                    "staticQuestionId": generateStaticQuestionId("Is considered dependable and reliable by consistently keeping commitments"),
                    "HTMLText": "<p>Is considered dependable and reliable by consistently keeping commitments</p>",
                    "isSelected": true,
                    "text": "Is considered dependable and reliable by consistently keeping commitments",
                    "value": uuidv4()
                },
                {
                    "subCategoryId": generateStaticQuestionId("Takes Accountability"),
                    "subCategory": "Takes Accountability",
                    "staticQuestionId": generateStaticQuestionId("Takes responsibility for mistakes and shares lessons learned to benefit the team"),
                    "HTMLText": "<p>Takes responsibility for mistakes and shares lessons learned to benefit the team</p>",
                    "isSelected": true,
                    "text": "Takes responsibility for mistakes and shares lessons learned to benefit the team",
                    "value": uuidv4()
                },
                {
                    "subCategoryId": generateStaticQuestionId("Takes Accountability"),
                    "subCategory": "Takes Accountability",
                    "staticQuestionId": generateStaticQuestionId("Adapts behavior based on feedback and self-reflection"),
                    "HTMLText": "<p>Adapts behavior based on feedback and self-reflection</p>",
                    "isSelected": true,
                    "text": "Adapts behavior based on feedback and self-reflection",
                    "value": uuidv4()
                },

                {
                    "subCategoryId": generateStaticQuestionId("Practice Courage & Resilience"),
                    "subCategory": "Practice Courage & Resilience",
                    "staticQuestionId": generateStaticQuestionId("Maintains composure in pressure-packed or highly frustrating situations"),
                    "HTMLText": "<p>Maintains composure in pressure-packed or highly frustrating situations</p>",
                    "isSelected": true,
                    "text": "Maintains composure in pressure-packed or highly frustrating situations",
                    "value": uuidv4()
                },
                {
                    "subCategoryId": generateStaticQuestionId("Practice Courage & Resilience"),
                    "subCategory": "Practice Courage & Resilience",
                    "staticQuestionId": generateStaticQuestionId("Persists and shows determination in challenging situations"),
                    "HTMLText": "<p>Persists and shows determination in challenging situations</p>",
                    "isSelected": true,
                    "text": "Persists and shows determination in challenging situations",
                    "value": uuidv4()
                },
                {
                    "subCategoryId": generateStaticQuestionId("Practice Courage & Resilience"),
                    "subCategory": "Practice Courage & Resilience",
                    "staticQuestionId": generateStaticQuestionId("Leads by example and as a role model for Applied Materials values"),
                    "HTMLText": "<p>Leads by example and as a role model for Applied Materials values</p>",
                    "isSelected": true,
                    "text": "Leads by example and as a role model for Applied Materials values",
                    "value": uuidv4()
                },
               
            ],
            "questionLabel": "Custom Matrix"
        }
    ],
    "isSelected": true,
    "type": "matrix",
    "dimension": "Self-Awareness"
}