import { v4 as uuidv4 } from "uuid";
import { generateStaticQuestionId } from "../../../../../utils/goalsUtils";

export const teamDevelopment = {
    "blockId": uuidv4(),
    "description": "<p>Grow your team: Optimize teams' potential, capacity, and performance by building high performing collaborative teams that are inspired and connected to the bigger picture, accountable, driven, and inclusive.</p>",
    "goalId": "dynamic360Strength",
    isRequired: true,
    feedbackBasedCondition: [
        {
            answeringCondition: [
                {
                    columnValues: ["1", "2"],
                    numberOfAnswersNeeded: 3,
                },
                {
                    columnValues: ["3", "4"],
                    numberOfAnswersNeeded: 3,
                },
                {
                    columnValues: ["5", "6"],
                    numberOfAnswersNeeded: 3,
                }
            ]
        }

    ],
    allowNAOption: true,
    "questions": [
        {
            "question": "",
            "options": [
                {
                    "label": "Never",
                    "value": "1"
                },
                {
                    "label": "Rarely",
                    "value": "2"
                },
                {
                    "label": "Occasionally",
                    "value": "3"
                },
                {
                    "label": "Sometimes",
                    "value": "4"
                },
                {
                    "label": "Usually",
                    "value": "5"
                },
                {
                    "label": "Always",
                    "value": "6"
                },
                { "label": "Can't answer", "value": "0" }
            ],
            "questionDimension": "Custom Matrix",
            "type": "matrix",
            "qid": uuidv4(),
            "matrixQuestions": [
                {
                    "subCategoryId": generateStaticQuestionId("Creates Clarity & Accountability"),
                    "subCategory": "Creates Clarity & Accountability",
                    "staticQuestionId": generateStaticQuestionId("Clearly communicates team goals, expectations, and timelines while ensuring alignment with broader business priorities"),
                    "HTMLText": "<p>Clearly communicates team goals, expectations, and timelines while ensuring alignment with broader business priorities</p>",
                    "isSelected": true,
                    "text": "Clearly communicates team goals, expectations, and timelines while ensuring alignment with broader business priorities",
                    "value": uuidv4()
                },
                // {
                //     "staticQuestionId": generateStaticQuestionId("Assigns clear accountability for objectives."),
                //     "HTMLText": "<p>Assigns clear accountability for objectives.</p>",
                //     "isSelected": true,
                //     "text": "Assigns clear accountability for objectives.",
                //     "value": uuidv4()
                // },
                {
                    "subCategoryId": generateStaticQuestionId("Creates Clarity & Accountability"),
                    "subCategory": "Creates Clarity & Accountability",
                    "staticQuestionId": generateStaticQuestionId("Proactively develops team capabilities to meet current and future business needs"),
                    "HTMLText": "<p>Proactively develops team capabilities to meet current and future business needs</p>",
                    "isSelected": true,
                    "text": "Proactively develops team capabilities to meet current and future business needs",
                    "value": uuidv4()
                },
                {
                    "subCategoryId": generateStaticQuestionId("Creates Clarity & Accountability"),
                    "subCategory": "Creates Clarity & Accountability",
                    "staticQuestionId": generateStaticQuestionId("Helps the team embrace changes and stay focused"),
                    "HTMLText": "<p>Helps the team embrace changes and stay focused</p>",
                    "isSelected": true,
                    "text": "Helps the team embrace changes and stay focused",
                    "value": uuidv4()
                },

                
                {
                    "subCategoryId": generateStaticQuestionId("Organizes for Impact"),
                    "subCategory": "Organizes for Impact",
                    "staticQuestionId": generateStaticQuestionId("Clarifies and prioritizes team's deliverables"),
                    "HTMLText": "<p>Clarifies and prioritizes team's deliverables</p>",
                    "isSelected": true,
                    "text": "Clarifies and prioritizes team's deliverables",
                    "value": uuidv4()
                },
                {
                    "subCategoryId": generateStaticQuestionId("Organizes for Impact"),
                    "subCategory": "Organizes for Impact",
                    "staticQuestionId": generateStaticQuestionId("Effectively allocates resources to ensure the team achieves its objectives"),
                    "HTMLText": "<p>Effectively allocates resources to ensure the team achieves its objectives</p>",
                    "isSelected": true,
                    "text": "Effectively allocates resources to ensure the team achieves its objectives",
                    "value": uuidv4()
                },

{
    "subCategoryId": generateStaticQuestionId("Organizes for Impact"),
    "subCategory": "Organizes for Impact",
    "staticQuestionId": generateStaticQuestionId("Establishes regular communication and reviews to ensure clarity of priorities and progress"),
    "HTMLText": "<p>Establishes regular communication and reviews to ensure clarity of priorities and progress</p>",
                    "isSelected": true,
    "text": "Establishes regular communication and reviews to ensure clarity of priorities and progress",
                    "value": uuidv4()
                },


                {
                    "subCategoryId": generateStaticQuestionId("Empowers Team For Scale"),
                    "subCategory": "Empowers Team For Scale",
                    "staticQuestionId": generateStaticQuestionId("Shows trust in team by delegating work and decisions, as appropriate"),
                    "HTMLText": "<p>Shows trust in team by delegating work and decisions, as appropriate</p>",
                    "isSelected": true,
                    "text": "Shows trust in team by delegating work and decisions, as appropriate",
                    "value": uuidv4()
                },
                 
                {
                    "subCategoryId": generateStaticQuestionId("Empowers Team For Scale"),
                    "subCategory": "Empowers Team For Scale",
                    "staticQuestionId": generateStaticQuestionId("Identifies and removes barriers to team performance that team members cannot solve themselves"),
                    "HTMLText": "<p>Identifies and removes barriers to team performance that team members cannot solve themselves</p>",
                    "isSelected": true,
                    "text": "Identifies and removes barriers to team performance that team members cannot solve themselves",
                    "value": uuidv4()
                },

                {
                    "subCategoryId": generateStaticQuestionId("Empowers Team For Scale"),
                    "subCategory": "Empowers Team For Scale",
                    "staticQuestionId": generateStaticQuestionId("Empowers team members resolve their conflicts constructively"),
                    "HTMLText": "<p>Empowers team members resolve their conflicts constructively</p>",
                    "isSelected": true,
                    "text": "Empowers team members resolve their conflicts constructively",
                    "value": uuidv4()
                },
            ],
            "questionLabel": "Custom Matrix"
        }
    ],
    "isSelected": true,
    "type": "matrix",
    "dimension": "High Performance Team"
}