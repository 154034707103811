import { v4 as uuidv4 } from "uuid";
import { generateStaticQuestionId } from "../../../../../utils/goalsUtils";

export const collaboration = {
    "blockId": uuidv4(),
    "description": "<p>Grow Connectivity: Inclusively collaborate a complex, matrix and changing environment within and across team boundaries(employees, peers, customers) to enable connectivity of people, capabilities, and technologies.</p>",
    "goalId": "dynamic360Strength",
    isRequired: true,
    feedbackBasedCondition: [
        {
            answeringCondition: [
                {
                    columnValues: ["1", "2"],
                    numberOfAnswersNeeded: 3,
                },
                {
                    columnValues: ["3", "4"],
                    numberOfAnswersNeeded: 3,
                },
                {
                    columnValues: ["5", "6"],
                    numberOfAnswersNeeded: 3,
                }
            ]
        }

    ],
    allowNAOption: true,
    "questions": [
        {
            "question": "",
            "options": [
                {
                    "label": "Never",
                    "value": "1"
                },
                {
                    "label": "Rarely",
                    "value": "2"
                },
                {
                    "label": "Occasionally",
                    "value": "3"
                },
                {
                    "label": "Sometimes",
                    "value": "4"
                },
                {
                    "label": "Usually",
                    "value": "5"
                },
                {
                    "label": "Always",
                    "value": "6"
                },
                { "label": "Can't answer", "value": "0" }
            ],
            "questionDimension": "Custom Matrix",
            "type": "matrix",
            "qid": uuidv4(),
            "matrixQuestions": [
                {
                    "subCategoryId": generateStaticQuestionId("Adopt Enterprise-wide Mindset"),
                    "subCategory": "Adopt Enterprise-wide Mindset",
                    "staticQuestionId": generateStaticQuestionId("Recognizes team members who demonstrate collaboration and teamwork"),
                    "HTMLText": "<p>Recognizes team members who demonstrate collaboration and teamwork</p>",
                    "isSelected": true,
                    "text": "Recognizes team members who demonstrate collaboration and teamwork",
                    "value": uuidv4()
                },
                {
                    "subCategoryId": generateStaticQuestionId("Adopt Enterprise-wide Mindset"),
                    "subCategory": "Adopt Enterprise-wide Mindset",
                    "staticQuestionId": generateStaticQuestionId("Prioritizes what's good for the enterprise rather than only focusing on own team's interests"),
                    "HTMLText": "<p>Prioritizes what's good for the enterprise rather than only focusing on own team's interests</p>",
                    "isSelected": true,
                    "text": "Prioritizes what's good for the enterprise rather than only focusing on own team's interests",
                    "value": uuidv4()
                },
                {
                    "subCategoryId": generateStaticQuestionId("Adopt Enterprise-wide Mindset"),
                    "subCategory": "Adopt Enterprise-wide Mindset",
                    "staticQuestionId": generateStaticQuestionId("Addresses collaboration problems with colleagues and their teams promptly by finding common ground"),
                    "HTMLText": "<p>Addresses collaboration problems with colleagues and their teams promptly by finding common ground</p>",
                    "isSelected": true,
                    "text": "Addresses collaboration problems with colleagues and their teams promptly by finding common ground",
                    "value": uuidv4()
                },
                {
                    "subCategoryId": generateStaticQuestionId("Understand Business Interlinkages"),
                    "subCategory": "Understand Business Interlinkages",
                    "staticQuestionId": generateStaticQuestionId("Understands key partners' business goals, work processes, challenges, critical success factors, etc."),
                    "HTMLText": "<p>Understands key partners' business goals, work processes, challenges, critical success factors, etc.</p>",
                    "isSelected": true,
                    "text": "Understands key partners' business goals, work processes, challenges, critical success factors, etc.",
                    "value": uuidv4()
                },
                {
                    "subCategoryId": generateStaticQuestionId("Understand Business Interlinkages"),
                    "subCategory": "Understand Business Interlinkages",
                    "staticQuestionId": generateStaticQuestionId("Clarifies key dependencies and expectations between own team and other teams"),
                    "HTMLText": "<p>Clarifies key dependencies and expectations between own team and other teams</p>",
                    "isSelected": true,
                    "text": "Clarifies key dependencies and expectations between own team and other teams",
                    "value": uuidv4()
                },
                {
                    "subCategoryId": generateStaticQuestionId("Understand Business Interlinkages"),
                    "subCategory": "Understand Business Interlinkages",
                    "staticQuestionId": generateStaticQuestionId("Listens actively to understand others' perspectives, challenges, and constraints"),
                    "HTMLText": "<p>Listens actively to understand others' perspectives, challenges, and constraints</p>",
                    "isSelected": true,
                    "text": "Listens actively to understand others' perspectives, challenges, and constraints",
                    "value": uuidv4()
                },
                {
                    "subCategoryId": generateStaticQuestionId("Enable Connectivity across Teams"),
                    "subCategory":"Enable Connectivity across Teams",
                    "staticQuestionId": generateStaticQuestionId("Intentionally builds and maintains relationships with colleagues outside own team whose partnership is critical to own team’s success"),
                    "HTMLText": "<p>Intentionally builds and maintains relationships with colleagues outside own team whose partnership is critical to own team’s success</p>",
                    "isSelected": true,
                    "text": "Intentionally builds and maintains relationships with colleagues outside own team whose partnership is critical to own team’s success",
                    "value": uuidv4()
                },
             
                {
                    "subCategoryId": generateStaticQuestionId("Enable Connectivity across Teams"),
                    "subCategory":"Enable Connectivity across Teams",
                    "staticQuestionId": generateStaticQuestionId("Influences others effectively without formal authority"),
                    "HTMLText": "<p>Influences others effectively without formal authority</p>",
                    "isSelected": true,
                    "text": "Influences others effectively without formal authority",
                    "value": uuidv4()
                },
                {
                    "subCategoryId": generateStaticQuestionId("Enable Connectivity across Teams"),
                    "subCategory":"Enable Connectivity across Teams",
                    "staticQuestionId": generateStaticQuestionId("Proactively manages conflicts to achieve business goals."),
                    "HTMLText": "<p>Proactively manages conflicts to achieve business goals.</p>",
                    "isSelected": true,
                    "text": "Proactively manages conflicts to achieve business goals.",
                    "value": uuidv4()
                },
            ],
            "questionLabel": "Custom Matrix"
        }
    ],
    "isSelected": true,
    "type": "matrix",
    "dimension": "Collaboration"
}